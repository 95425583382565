import React, { useContext } from "react";
import "../Styles/Promotions.scss";
import ChristmasPromo from "../Images/pantry_promo_2024.png"; // Keep as fallback
import MothersDayPromo from "../Images/BackgroundFillMothersDayOrange.png";
import FathersDay from "../Images/fathersDay.png";

const Promotions = ({ handleControlPromo, handleClosePromo }) => {
  return (
    <div className="promotions">
      {/* Video container replacing the image */}
      <div className="promotions-video-container">
        <video
          className="promotions-video"
          autoPlay
          muted
          loop
          playsInline
          poster={ChristmasPromo} // Use existing image as fallback
        >
          <source src="https://penyesa-pictures.s3.af-south-1.amazonaws.com/pantry_promo_2024.mp4" type="video/mp4" />
          {/* Fallback to image if video fails */}
          <img className="promotions-img" src={ChristmasPromo} alt="Christmas Promotion" />
        </video>
      </div>
      
      <div className="promotions-close" onClick={handleClosePromo}>
        <i className="far fa-window-close"></i>
      </div>
      
      <div className="promotions-shop" onClick={handleControlPromo}>
        SHOP NOW
      </div>
    </div>
  );
};

export default Promotions;